<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <v-chip
                small
                :color="background"
                class="pa-1 mr-2"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon small :color="color">
                    {{ icon }}
                </v-icon>
            </v-chip>
        </template>
        <span>{{ title }}</span>
    </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const AVideoTemplateChipProps = Vue.extend({
    name: 'AVideoTemplateChip',
    props: {
        title: {
            type: String,
            default() {
                return '';
            }
        },
        color: {
            type: String,
            default() {
                return '';
            }
        },
        background: {
            type: String,
            default() {
                return '';
            }
        },
        icon: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class AVideoTemplateChip extends AVideoTemplateChipProps {}
</script>
