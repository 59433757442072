import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('modal',{attrs:{"id":_vm.id,"title":_vm.title,"persistent":false},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(VContainer,{staticClass:"px-0 video-template-preview"},[_c(VRow,{attrs:{"no-gutters":""}},[(_vm.isLoading)?_c(VCol,{attrs:{"cols":"6"}},[_c(VSkeletonLoader,{staticClass:"ml-n4",attrs:{"loading":"","type":"article"}})],1):_c(VCol,{attrs:{"cols":"6"}},[_c('p',[_vm._v(_vm._s(_vm.selected.description))]),(_vm.templates.length > 1)?_c('div',[_c('h3',[_vm._v("Select Duration:")]),_c(VRadioGroup,{attrs:{"dense":"","hide-details":""},model:{value:(_vm.selectedId),callback:function ($$v) {_vm.selectedId=$$v},expression:"selectedId"}},_vm._l((_vm.templates),function(option,key){return _c(VRadio,{key:key,staticClass:"mb-4",attrs:{"value":key},on:{"change":function($event){return _vm.variation(key)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"ml-n8 d-flex align-center white"},[_c(VIcon,{attrs:{"left":"","small":"","color":key === _vm.selectedId
                                                    ? 'primary'
                                                    : 'secondary'}},[_vm._v(" clock ")]),_vm._v(" "+_vm._s(_vm.duration(option))+" ")],1)]},proxy:true}],null,true)})}),1)],1):_c('div',[_c('h3',[_vm._v("Duration:")]),_c('div',{staticClass:"d-flex align-center py-2"},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("clock")]),_vm._v(" "+_vm._s(_vm.duration(_vm.selected))+" ")],1)]),_c('div',{staticClass:"pt-4"},[_c('h4',[_vm._v("You will need:")]),_c('div',{staticClass:"d-flex align-center py-2"},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("align-left")]),_vm._v(" "+_vm._s(_vm.captions(_vm.selected))+" ")],1),(_vm.mediaCount(_vm.selected))?_c('div',{staticClass:"d-flex align-center py-2"},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("image")]),_vm._v(" "+_vm._s(_vm.medias(_vm.selected))+" ")],1):_vm._e()])]),_c(VCol,{attrs:{"cols":"6"}},[_c(VImg,{attrs:{"src":_vm.previewSrc,"min-height":"200"}}),(_vm.hasSamples)?_c('div',{staticClass:"mt-2"},[_c('h3',[_vm._v("Sample Videos")]),_c('ul',_vm._l((_vm.samples),function(sample,key){return _c('li',{key:key},[_c('a',{attrs:{"href":sample.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getSampleTitle(sample))+" ")])])}),0)]):_vm._e()],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"large":"","text":"","block":_vm.$vuetify.breakpoint.smAndDown},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c(VSpacer),_c(VBtn,{attrs:{"large":"","color":"primary","block":_vm.$vuetify.breakpoint.smAndDown,"disabled":_vm.isLoading},on:{"click":_vm.select}},[_vm._v(" Select template ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }