import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VSheet,_vm._g({staticClass:"rounded video-template",attrs:{"elevation":hover ? 5 : 1}},_vm.$listeners),[_c(VImg,{attrs:{"src":_vm.previewSrc,"min-height":"200"}},[_c(VCardTitle,{staticClass:"pa-2"},[(_vm.isPrivate)?_c('a-video-template-chip',{attrs:{"background":"white","color":"red darken-2","title":"Private Template","icon":"lock"}}):_vm._e(),(_vm.isNew)?_c('a-video-template-chip',{attrs:{"background":"green","color":"white","title":"New Template","icon":"certificate"}}):_vm._e()],1)],1),_c('div',{staticClass:"text-body-1 d-flex justify-center py-3",class:{
                'primary--text': hover
            }},[(_vm.isPremium)?_c('a-video-template-chip',{attrs:{"background":"white","color":"yellow darken-3","title":"Premium Template","icon":"gem"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.template.name)+" ")],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }