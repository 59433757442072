<template>
    <v-hover v-slot="{ hover }">
        <v-sheet
            class="rounded video-template"
            :elevation="hover ? 5 : 1"
            v-on="$listeners"
        >
            <v-img :src="previewSrc" min-height="200">
                <v-card-title class="pa-2">
                    <a-video-template-chip
                        v-if="isPrivate"
                        background="white"
                        color="red darken-2"
                        title="Private Template"
                        icon="lock"
                    />
                    <a-video-template-chip
                        v-if="isNew"
                        background="green"
                        color="white"
                        title="New Template"
                        icon="certificate"
                    />
                </v-card-title>
            </v-img>
            <div
                class="text-body-1 d-flex justify-center py-3"
                :class="{
                    'primary--text': hover
                }"
            >
                <a-video-template-chip
                    v-if="isPremium"
                    background="white"
                    color="yellow darken-3"
                    title="Premium Template"
                    icon="gem"
                />
                {{ template.name }}
            </div>
        </v-sheet>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import AVideoTemplateChip from './AVideoTemplateChip.vue';

import type { SlideshowTemplate } from '@/types/Slideshow';

const AVideoTemplateCardProps = Vue.extend({
    name: 'AVideoTemplateCard',
    props: {
        template: {
            type: Object as PropType<SlideshowTemplate>,
            default() {
                return {
                    is_new: false,
                    image: '',
                    name: '',
                    is_private: false,
                    is_premium: false
                };
            }
        }
    }
});

@Component({
    components: {
        AVideoTemplateChip
    }
})
export default class AVideoTemplateCard extends AVideoTemplateCardProps {
    get isPremium() {
        return this.template.is_premium;
    }

    get isPrivate() {
        return this.template.is_private;
    }

    get isNew() {
        return this.template.is_new === '1';
    }

    get previewSrc() {
        return `/video/templates/${this.template?.image}`;
    }
}
</script>

<style lang="scss" scoped>
.video-template::v-deep {
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    .v-image {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
</style>
